



















































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import CustomersList from "@/components/CustomersList.vue";
import { logout } from "@/auth";
import {abillsModule} from "@/store";

@Component({
  name: 'Main',
})
export default class Main extends Vue {
  opened = true;

    get is_operator(): boolean {
        return abillsModule.me.grp == 'op';
    }

    get is_admin(): boolean {
        return abillsModule.me.grp == 'su';
    }

  logout(){
    logout();
    this.$router.push('/login');
  }
}
