import axios from 'axios';
import { API_URL } from '@/settings';
/*
export async function login(username: string, password: string) {
  const data = new FormData();
  data.append('username', username);
  data.append('password', password);
  const resp = await axios.post(`${API_URL}/login`, data);
  const token = resp.data.access_token;
  localStorage.setItem('token', token);
}
*/
export async function login (username: string , password: string) {
    const resp = await axios({url: `${API_URL}/token`, data: `grant_type=password&username=${username}&password=${password}`, method: 'POST'})
    const token = resp.data.access_token;
    localStorage.setItem('token', token);
};

export async function logout() {
  localStorage.removeItem('token');
}
